import React, { useEffect, useState } from 'react'
import Imgix from 'react-imgix'
import { useHistory, useParams } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import API from '../../utils/API'
import {
  ABSpinner,
  useErrorService,
  useResolutionService,
} from '@abroad/components'
import { PageHeader } from '../common'
import { surveyType } from '../../utils/constants'
import { openLinkInTab } from '../../utils/openLinkInTab'
import { ModuleMiniDetails } from '../breakthrough'

const QuestsYour = () => {
  const { category } = useParams()
  const { isMDScreen } = useResolutionService()
  const [wisdomAssessmentLink, setWisdomAssessmentLink] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [count, setCount] = useState()
  const [questsHome, setQuestsHome] = useState({})
  const questHomeId = questsHome?._id
  const startDate = new Date(questsHome?.startDate)
  const endDate = new Date(questsHome?.endDate)
  const [expanded, setExpanded] = useState(false)
  const Error = useErrorService()
  const history = useHistory()
  useEffect(() => {
    const getCount = async () => {
      try {
        const { data } = await API.quests.getToDoListCount(questHomeId)
        setCount(data)
        setIsLoading(false)
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }
    if (questHomeId) getCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questHomeId])

  useEffect(() => {
    const getQuestsHome = async () => {
      try {
        const { data: quest } = await API.quests.questsHomeGet()
        if (Object.keys(quest).length === 0) {
          const { data: questAll } = await API.quests.allQuest()
          setQuestsHome(questAll[0])
          setIsLoading(false)
        } else {
          setQuestsHome(quest)
          setIsLoading(false)
        }
      } catch (e) {
        Error.showError(e)
        setIsLoading(false)
      }
    }

    getQuestsHome()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleWisdomAssessmentClick = async () => {
    try {
      const { data } = await API.survey.getLink(surveyType.WISDOM)
      setWisdomAssessmentLink(data.link)
      setIsLoading(false)
      if (data?.code === 'upgrade_plan') {
        Error.showError(data)
      } else {
        openLinkInTab(data?.link, '_self')
      }
    } catch (e) {
      Error.showError(e)
      setIsLoading(false)
    }
  }

  const getDay = (dateString) => {
    const date = new Date(dateString)
    return date.getUTCDate()
  }

  const startDay = getDay(startDate)
  const endDay = getDay(endDate)

  if (!questsHome || Object.keys(questsHome).length === 0) {
    return null
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
  }
  const getDescription = () => {
    if (expanded) {
      return questsHome.description
    } else {
      return questsHome.description.length > 550
        ? questsHome.description.slice(0, 550) + '...'
        : questsHome.description
    }
  }

  const yourQuests = [
    {
      title: 'Get to Know Your Faculty',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/TodoList.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/faculties` : '',
      count: count?.facultyCount > 0 && count?.facultyCount,
    },
    {
      title: 'Take Wisdom Assessment',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/Learning.png`,
      link: `${wisdomAssessmentLink}`,
      onClick: handleWisdomAssessmentClick,
    },
    {
      title: 'Schedule Assessment Review with Coach',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/Intentions.png`,
      link: questsHome?._id
        ? `/your-quest/${questsHome?._id}/schedule-assessment-review`
        : '',
    },
    {
      title: 'Clarify Your Intentions',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/Faculty.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/intentions` : '',
      count: count?.intensionCount > 0 && count?.intensionCount,
    },
    {
      title: 'Review Travel Checklist',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/YourQuestTravelChecklist.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/todos` : '',
      count: count?.todoListCount > 0 && count?.todoListCount,
    },
    {
      title: 'Review Itinerary',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/YourQuestItinerary.png`,
      link: questsHome?._id ? `/your-quest/${questsHome?._id}/itinrary` : '',
    },
    {
      title: 'Learning Resources to Prep for Quest',
      imageUrl: `${process.env.REACT_APP_IMG_SOURCE}/YourQuestLearningResources.png`,
      link:
        questsHome?._id && category
          ? `/your-quest/${questsHome?._id}/learning-resources/:category`
          : `/your-quest/${questsHome?._id}/learning-resources/Listen`,
    },
  ]

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <PageHeader title='Your Quest' />
      <div className='mb-3'>
        {questsHome && (
          <div>
            <div className='row g-0 program-wrp cursor-pointer border-radius-5 p-32px custom-gray-bg-100 mt-24px'>
              <div className='col col-lg-4 col-12'>
                <div className='position-relative overflow-hidden border-radius-5'>
                  <Imgix
                    className='border-radius-5 img-cover w-100 program-img-zoom lazyload'
                    imgixParams={{
                      fit: 'crop',
                      crop: 'edges',
                      ar: isMDScreen ? '3:1' : '4:5',
                    }}
                    src={`${process.env.REACT_APP_IMG_SOURCE}/quest/${questHomeId}/${questsHome.imageFileName}`}
                    alt='image'
                    attributeConfig={{
                      src: 'data-src',
                      srcSet: 'data-srcset',
                      sizes: 'data-sizes',
                    }}
                    htmlAttributes={{
                      src: `${
                        process.env.REACT_APP_IMG_SOURCE
                      }/quest/${questHomeId}/${questsHome.imageFileName}?ar=${
                        isMDScreen ? '3:1' : '4:5'
                      }&fit=crop&crop=edges&fill=blur&blur=300&px=16&auto=format`, // low quality image here
                    }}
                  />
                  <div className='position-absolute inset-0 d-flex justify-content-center align-items-center px-5 border-radius-10'></div>
                </div>
              </div>
              <div className='col col-lg-8 col-12 pl-lg-32px pt-lg-0 pt-3 d-flex flex-column justify-content-center'>
                <ModuleMiniDetails
                  showDots={false}
                  details={{
                    label1: `${questsHome.month}`,
                    label2: `${startDay}-${endDay},`,
                    label3: `${questsHome.year}`,
                  }}
                />
                <div className='s2 my-12px'>
                  <span className='fst-italic'>{questsHome.title}</span>
                </div>
                <pre
                  onClick={(e) => {
                    e.stopPropagation()
                    toggleExpand()
                  }}
                  className='s7 mb-0 pre'
                  dangerouslySetInnerHTML={{ __html: getDescription() }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        {yourQuests.map((task, index) => (
          <div
            key={index}
            onClick={task.onClick || (() => history.push(task.link))}>
            <div className='cursor-pointer cursor-hover py-16px d-flex align-items-center justify-content-between'>
              <div className='d-flex align-items-center'>
                <div className='pr-16px'>
                  <Image
                    src={task.imageUrl}
                    className='border-radius-10'
                    style={{ height: '60px', width: '60px' }}
                    alt='image'
                  />
                </div>
                {task.count && (
                  <div className='numerical-msg rounded-circle s-captions d-flex align-items-center justify-content-center me-1'>
                    {task.count}
                  </div>
                )}
                <div className='s6 font-hover'>{task.title}</div>
              </div>
              <div className='icon icon-right-arrow s9'></div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default QuestsYour
