import React from 'react'
import { Switch, Redirect, useLocation } from 'react-router-dom'
import {
  useUserService,
  simple360Url,
  coachRecommendsUrl,
  leaderAssessmentsUrl,
} from '@abroad/components'
import PrivateRoute from './PrivateRoute'
import {
  NoAwarenessAssessment,
  PersonalSurveyResult,
  SurveyComparison,
  Home,
  MediaInfo,
  Favorites,
  Profile,
  SurveyCategory,
  MediaLibrary,
  NotFound,
  UserPayment,
  UnsubscribeEmail,
  UnderMaintenance,
  Program,
  ProgramsOverview,
  Simple360,
  LeaderAssessments,
  JourneysWrapper,
  GrowthPlan,
  Intention,
  LAResults,
  AskQuestion,
  DownloadApp,
  GlobalJournal,
  CoachingProvider,
  SessionDetails,
  UpgradeInquiry,
  CoachingPlanPayment,
  ChangeCard,
  Quests,
  BeginWisdomAssessment,
  WisdomAssessmentResult,
  CoachAndCircles,
} from '../pages'
import { FAQsListing, ModuleDetails, ScheduledDetail } from '../components'
import { Layout, MediaControlWrapper } from '../components/layout'
import UpcomingQuests from '../components/quests/UpcomingQuests'
import YourQuestsToDo from '../components/quests/YourQuestsToDo'
import QuestsFaculty from '../components/quests/QuestsFaculty'
import QuestsIntention from '../components/quests/QuestsIntention'
import QuestsItinerary from '../components/quests/QuestsItinerary'
import Gallery from '../components/quests/Gallery'
import { IntentionQuest } from '../components/quests'
import QuestsYour from '../components/quests/QuestsYour'
import QuestsLearningResources from '../components/quests/QuestsLearningResources'
import QuestsLearningResourcesBlogDetails from '../components/quests/QuestsLearningResourcesBlogDetails'
import IndividualSessionScheduling from '../components/circles/IndividualSessionScheduling'
import QuestsScheduleAssessmentReview from '../components/quests/QuestsScheduleAssessmentReview'
import BookCircleModal from '../components/circles/BookCircleModal'
/**
 * Navigaton routes for the app
 *
 * @info [react-router-dom](https://reactrouter.com/web/guides/quick-start)
 */
const AuthenticatedRoutes = () => {
  const { user } = useUserService()
  const location = useLocation()

  return (
    <>
      <BookCircleModal user={user} />
      <Switch>
        <PrivateRoute exact path='/404' component={NotFound} />
        <PrivateRoute
          exact
          path='/under-maintenance'
          component={UnderMaintenance}
        />
        <PrivateRoute
          exact
          path='/email/unsubscribe'
          component={UnsubscribeEmail}
        />
        <MediaControlWrapper>
          <Switch>
            <PrivateRoute
              path='/media/:mediaCategory/:categoryId/:mediaId'
              component={MediaInfo}
            />
            <PrivateRoute
              path={[
                '/survey',
                '/survey/comparison-report',
                '/survey/:id',
                '/survey/leader/:id',
                '/survey/category/:category',
                '/module/:programId/:moduleId',
                '/breakthrough',
                '/programs',
                '/program/:programId',
                '/media-library/:id?',
                '/home',
                '/home/purpose-vision',
                '/home/values-principles',
                '/favorites',
                '/user-payment',
                '/on-demand',
                '/Profile',
                '/Profile/update-password',
                '/coaching',
                '/coaching/intentions',
                '/coaching/intentions/:intentionId',
                '/coaching/sessions',
                '/coaching/session/:sessionId',
                '/coaching/objectives',
                `/survey/requests/${leaderAssessmentsUrl}`,
                `/survey/requests/${leaderAssessmentsUrl}/:id`,
                `/survey/requests/${simple360Url}`,
                `/${coachRecommendsUrl}`,
                '/ask-question',
                '/download-app',
                '/journal',
                '/journal/:categoryType/:subCategoryId/:mediaId',
                '/upgrade-inquiry',
                '/profile/plans-payments/coaching-plan/:coachingPlanId',
                '/profile/plans-payments/coaching-plan/:coachingPlanId/card/:cardId/change-card',
                '/quests',
                '/your-quest',
                '/your-quest/:questId/todos',
                '/your-quest/:questId/learning-resources/:category',
                '/your-quest/:questId/learning-resources/:blogId/blog-detail',
                '/your-quest/:questId/faculties',
                '/your-quest/:questId/intentions',
                '/your-quest/:questId/intentions/:intentionId',
                '/your-quest/:questId/itinrary',
                '/quests/:questId/public',
                '/wisdom',
                '/wisdom/:id',
                '/wisdom/comparison-report',
                '/faqs',
                '/schedule-session',
                '/schedule-session/individual',
                '/schedule-detail',
              ]}>
              <Layout>
                <Switch>
                  <PrivateRoute
                    path={`/survey`}
                    component={NoAwarenessAssessment}
                    exact
                  />
                  <PrivateRoute
                    path={[`/survey/requests/${simple360Url}`]}
                    component={Simple360}
                    exact
                  />
                  <PrivateRoute
                    path={[
                      `/survey/requests/${leaderAssessmentsUrl}`,
                      `/survey/requests/${leaderAssessmentsUrl}/:id`,
                    ]}
                    component={LeaderAssessments}
                    exact
                  />
                  <PrivateRoute
                    exact
                    path={`/survey/comparison-report`}
                    component={SurveyComparison}
                  />
                  <PrivateRoute
                    exact
                    path={`/survey/:id`}
                    component={PersonalSurveyResult}
                  />
                  <PrivateRoute
                    exact
                    path={`/survey/leader/:id`}
                    component={LAResults}
                  />
                  <PrivateRoute
                    exact
                    path={`/survey/category/:category`}
                    component={SurveyCategory}
                  />
                  <PrivateRoute
                    path='/module/:programId/:moduleId'
                    component={ModuleDetails}
                  />
                  <PrivateRoute
                    exact
                    path='/program/:programId'
                    component={Program}
                  />
                  <PrivateRoute
                    exact
                    path='/programs'
                    component={ProgramsOverview}
                  />
                  <PrivateRoute
                    exact
                    path='/media-library/:id?'
                    component={MediaLibrary}
                  />
                  <PrivateRoute
                    exact
                    path={[
                      '/home',
                      '/home/purpose-vision',
                      '/home/values-principles',
                    ]}
                    component={Home}
                  />
                  <PrivateRoute path='/favorites' component={Favorites} />
                  <PrivateRoute
                    exact
                    path='/user-payment'
                    component={UserPayment}
                  />
                  <PrivateRoute
                    path='/profile/plans-payments/coaching-plan/:coachingPlanId'
                    component={CoachingPlanPayment}
                    exact
                  />
                  <PrivateRoute
                    path='/profile/plans-payments/coaching-plan/:coachingPlanId/card/:cardId/change-card'
                    component={ChangeCard}
                    exact
                  />
                  <PrivateRoute
                    path={[
                      `/Profile`,
                      '/profile/my-information',
                      '/profile/plans-payments',
                    ]}
                    component={Profile}
                    exact
                  />
                  <PrivateRoute
                    exact
                    path={`/Profile/update-password`}
                    component={Profile}
                  />
                  <PrivateRoute
                    exact
                    path={`/coaching/intentions`}
                    component={GrowthPlan}
                  />
                  <PrivateRoute
                    exact
                    path={`/coaching/intentions/:intentionId`}
                    component={Intention}
                  />
                  <PrivateRoute
                    exact
                    path={`/coaching/sessions`}
                    component={CoachingProvider}
                  />
                  <PrivateRoute
                    exact
                    path={`/coaching/session/:sessionId`}
                    component={SessionDetails}
                  />
                  <PrivateRoute
                    exact
                    path={`/${coachRecommendsUrl}`}
                    component={JourneysWrapper}
                  />
                  <PrivateRoute exact path={`/quests`} component={Quests} />
                  <PrivateRoute
                    exact
                    path={`/your-quest`}
                    component={QuestsYour}
                  />
                  <PrivateRoute
                    exact
                    path={`/quests/:questId/gallery`}
                    component={Gallery}
                  />
                  <PrivateRoute
                    exact
                    path={'/quests/:questId/public'}
                    component={UpcomingQuests}
                  />
                  <PrivateRoute
                    exact
                    path={'/your-quest/:questId/todos'}
                    component={YourQuestsToDo}
                  />
                  <PrivateRoute
                    exact
                    path={'/your-quest/:questId/schedule-assessment-review'}
                    component={QuestsScheduleAssessmentReview}
                  />
                  <PrivateRoute
                    exact
                    path={'/your-quest/:questId/learning-resources/:category'}
                    component={QuestsLearningResources}
                  />
                  <PrivateRoute
                    exact
                    path={
                      '/your-quest/:questId/learning-resources/:blogId/blog-detail'
                    }
                    component={QuestsLearningResourcesBlogDetails}
                  />
                  <PrivateRoute
                    exact
                    path={'/your-quest/:questId/faculties'}
                    component={QuestsFaculty}
                  />
                  <PrivateRoute
                    exact
                    path={'/your-quest/:questId/intentions'}
                    component={QuestsIntention}
                  />
                  <PrivateRoute
                    exact
                    path={'/your-quest/:questId/intentions/:intentionId'}
                    component={IntentionQuest}
                  />
                  <PrivateRoute
                    exact
                    path={'/your-quest/:questId/itinrary'}
                    component={QuestsItinerary}
                  />
                  <PrivateRoute
                    exact
                    path='/ask-question'
                    component={AskQuestion}
                  />
                  <PrivateRoute
                    exact
                    path='/download-app'
                    component={DownloadApp}
                  />
                  <PrivateRoute
                    exact
                    path={[
                      `/journal`,
                      '/journal/:categoryType/:subCategoryId/:mediaId',
                    ]}
                    component={GlobalJournal}
                  />
                  <PrivateRoute
                    path='/upgrade-inquiry'
                    component={UpgradeInquiry}
                  />
                  <PrivateRoute
                    path='/schedule-session'
                    component={CoachAndCircles}
                    exact
                  />
                  <PrivateRoute path='/faqs' component={FAQsListing} exact />
                  <PrivateRoute
                    path='/schedule-session/individual'
                    component={IndividualSessionScheduling}
                    exact
                  />
                  <PrivateRoute
                    exact
                    path='/schedule-detail'
                    component={ScheduledDetail}
                  />
                  <PrivateRoute
                    path='/wisdom/comparison-report'
                    component={SurveyComparison}
                    exact
                  />
                  <PrivateRoute
                    path='/wisdom'
                    component={BeginWisdomAssessment}
                    exact
                  />
                  <PrivateRoute
                    path='/wisdom/:id'
                    component={WisdomAssessmentResult}
                    exact
                  />
                  {user ? (
                    <Redirect to='/home' />
                  ) : (
                    <Redirect
                      to={`/${location?.search ? location?.search : ''}`}
                    />
                  )}
                </Switch>
              </Layout>
            </PrivateRoute>
            {user ? (
              <Redirect to='/home' />
            ) : (
              <Redirect to={`/${location?.search ? location?.search : ''}`} />
            )}
          </Switch>
        </MediaControlWrapper>
      </Switch>
    </>
  )
}

export default AuthenticatedRoutes
