import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Form as RBForm, Row, Col } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import {
  useErrorService,
  useNotificationService,
  Button,
  useUserService,
} from '@abroad/components'
import API from '../../utils/API'
import { PageHeader } from '../common'
import { InputField } from '../form'

const renderHelmet = () => {
  return (
    <Helmet>
      <title>{'Schedule-Assessment-Review | Abroad'}</title>
      <meta name='title' content={'Schedule-Assessment-Review | Abroad'}></meta>
      <meta
        property='og:title'
        content={'Schedule-Assessment-Review | Abroad'}></meta>
      <meta
        property='twitter:title'
        content={'Schedule-Assessment-Review | Abroad'}></meta>
      <link
        rel='canonical'
        href={`${process.env.REACT_APP_DOMAIN}/quests`}></link>
      <meta
        property='og:url'
        content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
      <meta
        property='twitter:url'
        content={`${process.env.REACT_APP_DOMAIN}/quests`}></meta>
    </Helmet>
  )
}

const QuestsScheduleAssessmentReview = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const Notification = useNotificationService()
  const Error = useErrorService()
  const history = useHistory()
  const { user } = useUserService()

  const onSubmit = async (values, action) => {
    setIsSubmitting(true)
    try {
      setIsLoading(true)
      await API.user.submitHelp({
        email: user?.email,
        name: user?.fullName,
        message: values.body,
        subject: values.subject,
      })
      setIsLoading(false)
      setIsSubmitting(false)
      Notification.showNotification(
        'Thank you. We will get in touch with you for your assessment review and growth plan.',
        'success',
      )
    } catch (e) {
      setIsSubmitting(false)
      setIsLoading(false)
      Error.showError(e)
    }
  }
  return (
    <>
      {renderHelmet()}
      <main>
        <PageHeader
          showBackArrow={true}
          version='small'
          title='Your Quest'
          backArrowAction={() => history.goBack()}
        />
        <h1 className='s1 py-16px'>Schedule Assessment Review with Coach</h1>
        <section className='ask-question'>
          <Formik
            onSubmit={onSubmit}
            initialValues={{
              subject: 'Schedule Wisdom Assessment Review with Coach',
              body: `Stacy,
  
The best times for me to schedule a 1-hour review call with a coach during the next couple weeks are...`,
            }}>
            {({ handleSubmit }) => (
              <RBForm as={Form} className='my-info px-0 mt-32px '>
                <InputField
                  type='text'
                  name='subject'
                  label='SUBJECT'
                  disabled
                />
                <InputField
                  name='body'
                  label='BODY'
                  as='textarea'
                  rows='12'
                  disabled={isLoading}
                />
                <Row>
                  <Col className='d-flex justify-content-end'>
                    <Button
                      type='submit'
                      onClick={() => {
                        handleSubmit()
                      }}
                      isLoading={isLoading}
                      disabled={isLoading || isSubmitting}
                      variant='saffron'
                      size='md'
                      className='btn s9 rounded'>
                      SUBMIT
                    </Button>
                  </Col>
                </Row>
              </RBForm>
            )}
          </Formik>
        </section>
      </main>
    </>
  )
}

export default QuestsScheduleAssessmentReview
